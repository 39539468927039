import clsx from 'clsx';
import { ReactElement, ReactNode, cloneElement, isValidElement } from 'react';
import { useMediaQuery } from '../hooks/useMediaQuery';

type ElementWithClassName = ReactElement<{ className?: string }>;

/**
 * Clones a React element and adds the ability to add additional classNames.
 *
 * @param element The React element to clone.
 * @param  className class names to add.
 * @returns A new React element with the additional class names.
 */
export function cloneElementWithClass(
  element: ReactNode,
  className: string,
): ReactNode {
  if (!element || !isValidElement(element)) {
    return null;
  }

  const elementWithClass = element as ElementWithClassName;

  return cloneElement(elementWithClass, {
    className: clsx(className, elementWithClass.props.className),
  });
}

export const useIsMobile = () => {
  return useMediaQuery('(max-width: 639px)');
};
