import { User as Auth0User } from '@auth0/auth0-react';
import type { IApiRole } from '@styluxinc/api-schema';

export type IEntityType = 'ORDERS' | 'INVENTORY' | 'OFFERS' | 'PRODUCTS';

export class User {
  name: string | null = null;
  _userProfile: Auth0User | null = null;
  accessToken: null | string = null;
  roleSet = new Set<string>();

  constructor({
    name = null,
    userProfile = null,
  }: {
    accessToken?: string | null;
    name?: string | null;
    userProfile?: Auth0User | null;
  } = {}) {
    this.name = name;
    this.userProfile = userProfile;
  }

  set userProfile(userProfile: Auth0User | null) {
    this._userProfile = userProfile;

    const maybeRoles = userProfile
      ? userProfile['https://api.stylux.io/roles']
      : [];

    this.roleSet = new Set(Array.isArray(maybeRoles) ? maybeRoles : []);
  }

  get userProfile() {
    return this._userProfile;
  }

  hasRole(roleOrRoles: IApiRole | IApiRole[]) {
    const roleArr = Array.isArray(roleOrRoles) ? roleOrRoles : [roleOrRoles];
    return roleArr.some((role) => this.roleSet.has(role));
  }

  hasAccessTo(entityType: IEntityType) {
    if (entityType === 'ORDERS') {
      return this.hasRole([
        'Stylux Internal Super Admin',
        'SUPER_ADMIN',
        'THIRD_PARTY_SUPPORT_ORDERS',
        'MERCHANT_ORDERS',
      ]);
    }

    if (entityType === 'INVENTORY') {
      return this.hasRole([
        'Stylux Internal Super Admin',
        'SUPER_ADMIN',
        'MERCHANT_INVENTORY',
      ]);
    }

    if (entityType === 'OFFERS') {
      return this.hasRole([
        'Stylux Internal Super Admin',
        'SUPER_ADMIN',
        'MERCHANT_OFFERS',
      ]);
    }

    return this.hasRole(['Stylux Internal Super Admin', 'SUPER_ADMIN']);
  }
}
